<template>
  <Banner bg-image="blue-bg.jpg" talk-img="help-for-someone-with.png">
    <template v-slot:title>
      Help for someone with suicidal thoughts, intentions or plans</template
    >
    <template v-slot:description>
      What should I do if my friend or family member is having thoughts of
      suicide?
    </template>
  </Banner>
  <section class="py-10 my-8 talk-sect md:my-12 md:py-1s4">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <!-- 1 -->
      <Collapsible>
        <template v-slot:header>
          <span class="text-2xl px-2.5"> 1. Be aware</span>
        </template>

        <template v-slot:content>
          <div class="px-8 py-4 bg-blue-100">
            <router-link
              :to="{ name: 'SuicideWarningSigns' }"
              class="underline text-green"
            >
              Know the warning signs of suicide.
            </router-link>
          </div>
        </template>
      </Collapsible>

      <br />

      <Collapsible>
        <template v-slot:header>
          <span class="text-2xl px-2.5"> 2. Talk to them</span>
        </template>
        <template v-slot:content>
          <div class="px-8 py-4 bg-blue-100">
            <p class="py-4">
              Don’t be afraid to open up a conversation about suicide. Talking
              about suicide can be a difficult conversation to have, but don’t
              let this discourage you. Having a supportive conversation about
              how someone is doing won’t plant the idea of suicide in someone’s
              head; instead, raising the topic will make it easier for your
              friend/family to confide in you.
            </p>
            <div
              class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
            >
              When you talk to them, be honest and direct.
            </div>
            <p>
              Tell your friend or family member why you’re worried about them,
              and ask about suicide. Tell them you want to know how they really
              are, and that it’s okay to talk about suicide.
            </p>
            <ul class="my-4 about-ul">
              <li>Check in with them - “How are you feeling these days?”</li>
              <li>
                Express your concerns - “I love you and I’m worried about you.”
              </li>
              <li>
                Be supportive - “You can tell me anything. I will not judge. I
                will accept you no matter what.”
              </li>
              <li>
                Remark on what you’re noticing - “It seems like things have been
                stressful for you lately.”
              </li>
              <li>
                Ask about suicide - “Do you ever feel that life isn’t worth
                living?”
              </li>
              <li>
                Ask more if needed – “Can you tell me more about what you have
                been thinking?”
              </li>
            </ul>
            <div
              class="py-4 mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
            >
              If they do confide in you, here are some things you can say:
            </div>
            <ul class="about-ul">
              <li>“Thank you for letting me know. Can I give you a hug… “</li>
              <li>“I’m here for you. How can I support you?”</li>
              <li>
                “Do you want me to just listen? Would you like me to give make
                some suggestions?”
              </li>
            </ul>
          </div>
        </template>
      </Collapsible>

      <br />

      <Collapsible>
        <template v-slot:header>
          <span class="text-2xl px-2.5"> 3. Listen to them</span>
        </template>
        <template v-slot:content>
          <div class="px-8 py-4 bg-blue-100">
            <p>
              Just listening is one of the most helpful things you can do. Do
              not judge, criticize or minimize their feelings and concerns.
            </p>
            <ul class="py-4 about-ul">
              <li>
                Be willing to listen. Allow for expressions of feelings and
                accept those feelings.
              </li>
              <li>
                Don’t debate whether suicide is right or wrong, or feelings are
                good or bad. Don’t lecture on the value of life.
              </li>
              <li>
                Don’t give advice by making decisions for someone else to tell
                them to behave differently.
              </li>
              <li>Don’t ask “why.” This encourages defensiveness.</li>
              <li>Offer empathy, not sympathy.</li>
              <li>Don’t act shocked. This creates distance.</li>
              <li>
                Offer hope that alternatives are available, do not offer
                insincere reassurance; it only proves you don’t understand.
              </li>
              <li>
                Get involved. Become available. Show interest and support.
              </li>
            </ul>
          </div>
        </template>
      </Collapsible>
      <br />
      <Collapsible>
        <template v-slot:header>
          <span class="text-2xl px-2.5"> 4. Get them help</span>
        </template>
        <template v-slot:content>
          <div class="px-8 py-4 bg-blue-100">
            <p>
              It’s okay if you don’t know how; the ideas on this page can get
              you started.
            </p>
            <ul class="py-4 about-ul">
              <li>Don’t be sworn to secrecy. Seek support.</li>
              <li>Take action. Remove means.</li>
              <li>
                Get help from professionals, elders or agencies specializing in
                crisis intervention and suicide prevention.
              </li>
            </ul>
          </div>
        </template>
      </Collapsible>
      <br />
    </div>
  </section>

  <!-- Quick Access section Part 1-->
  <section class="py-8 bg-white md:my-14 md:pb-14 gradient-white">
    <div class="max-w-6xl px-4 mx-auto text-center sm:px-6">
      <div class="mb-6 text-2xl font-bold md:text-3xl text-blue">
        If your friend or family member is having thoughts about suicide right
        now, it is important to know that help is available.
      </div>
      <div class="mb-6 text-2xl font-bold md:text-3xl">Help to stay calm</div>
      <p class="w-full mx-auto md:w-3/4">
        Here are some exercises to help them get grounded and calm down so they
        are able to think more clearly.
      </p>
      <div class="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-8 gap-x-6">
        <router-link :to="{ name: 'PracticeGuidedMeditation' }">
          <QuickAccess
            arrow="text-green"
            text="Practice guided meditation"
            img="meditation.svg"
          />
        </router-link>
        <router-link :to="{ name: 'PracticeBreathingExercises' }">
          <QuickAccess
            arrow="text-red"
            text="Practice breathing exercises"
            img="exercises.svg"
          />
        </router-link>
      </div>
    </div>
  </section>
  <!-- Quick Access section Part 2 -->

  <section class="my-8 bg-white md:my-14 md:pb-14">
    <div class="max-w-6xl px-4 mx-auto text-center sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl">
        Help from a professional or other adult
      </div>
      <p class="w-full mx-auto md:w-3/4">
        You can also help your friend call the crisis line, or help them book an
        appointment to see a counsellor or encourage them to get help from an
        adult.
      </p>
      <div
        class="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-8 gap-x-6 icon-btns"
      >
        <router-link :to="{ name: 'CallACrisisHotline' }">
          <QuickAccess
            arrow="text-green"
            text="Call a crisis hotline"
            img="call-a-crisis-hotline.svg"
          />
        </router-link>
        <router-link :to="{ name: 'FindACounselor' }">
          <QuickAccess
            arrow="text-yellow"
            text="Find a counselor"
            img="find-a-counselor.svg"
          />
        </router-link>
      </div>
    </div>
  </section>

  <FooterCard
    img="help-for-someone-else-braid.png"
    text="Learn coping strategies"
    name="AdditionalCopingStrategies"
  >
    <template v-slot:header>Learn more coping strategies</template>
    <template v-slot:content>
      From naming emotions to creating a safety plan, there are a number of ways
      you can help you loved one get through this difficult time.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Collapsible from "@/components/Collapsible.vue";
import QuickAccess from "@/components/QuickAccess.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "HelpForSomeoneElse",
  components: {
    Banner,
    Collapsible,
    QuickAccess,
    FooterCard,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
ul.about-ul li {
  padding: 8px 0 8px 50px;

  position: relative;
}
ul.about-ul li::after {
  content: "";
  background: #22b46e;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 15px;
}
</style>
